<template>
  <section
    id="about"
    class="overflow-hidden"
  >
    <v-row
      class="white"
      no-gutters
    >
      <v-col
          cols="12"
          md="6"
          class="pa-10 pa-md-8"
          align-self="center"
      >
        <base-heading class="hidden-md-and-down font-weight-medium info--text">{{ $t("about.title") }}</base-heading>
        <base-subheading>{{ $t("about.member") }}</base-subheading>
        <base-subheading>{{ $t("about.hobby") }}</base-subheading>
        <base-subheading>{{ $t("about.motto") }}</base-subheading>
        <v-row class="justify-center">
          <span
              class="text-h3 font-weight-medium font-italic my-8 info--text"
          >
          "Carpe diem et noctem"
        </span>
        </v-row>
        <v-row
            justify="center"
            justify-md="left"
        >
        </v-row>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/gerhard_ktt.jpeg')"
          height="100%"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    data: () => ({
      skills: [
        {
          name: 'Web Design',
          value: 100,
        },
        {
          name: 'Web Development',
          value: 75,
        },
        {
          name: 'Web Support',
          value: 90,
        },
      ],
    }),
  }
</script>
